import { Flex, Link, Button, Text } from '@chakra-ui/react'

import { Icon } from '@miimosa/design-system'

const Footer = () => {
  return (
    <Flex w="full" bg="orange.200" py={10} direction="column" rowGap={8}>
      <Flex w="full" direction="row" justifyContent="space-around">
        <Flex direction="column" rowGap={4} alignItems="center">
          <Link href="https://www.miimosa.com" isExternal>
            <Icon name="Logo2" size="xl" color="white" h="56px" />
          </Link>
          <Flex direction="row" columnGap={4} alignItems="center">
            <Link isExternal href="https://www.linkedin.com/company/miimosa">
              <Icon name="LinkedIn" size="4xs" color="white" />
            </Link>
            <Link isExternal href="https://www.instagram.com/MiiMOSA_fr">
              <Icon name="Instagram" size="4xs" color="white" />
            </Link>
            <Link isExternal href="https://www.facebook.com/MiiMOSAfr">
              <Icon name="Facebook" size="4xs" color="white" />
            </Link>
            <Link isExternal href="https://twitter.com/MiiMOSA_fr">
              <Icon name="Twitter" size="4xs" color="white" />
            </Link>
          </Flex>
          <Link isExternal href="https://blog.miimosa.com/">
            <Button bg="white">
              <Text color="green.800" size="md" fontWeight="700">
                Le Blog
              </Text>
            </Button>
          </Link>
        </Flex>
        <Flex direction="column" rowGap={4} alignItems="center">
          <Icon name="LesieurClassicLogo" size="sm" />
          <Flex direction="row" columnGap={4} alignItems="center">
            <Link isExternal href="https://www.linkedin.com/company/lesieur/posts/?feedView=all">
              <Icon name="LinkedIn" size="4xs" color="white" />
            </Link>
            <Link isExternal href="https://www.instagram.com/lesieur_officiel">
              <Icon name="Instagram" size="4xs" color="white" />
            </Link>
            <Link isExternal href="https://fr-fr.facebook.com/lesieur.france">
              <Icon name="Facebook" size="4xs" color="white" />
            </Link>
          </Flex>
          <Flex direction="column" alignItems="center" rowGap={2}>
            <Text color="white" size="sm" fontWeight="700">
              En savoir plus sur
            </Text>
            <Link isExternal href="https://www.lesieur.fr/huiles-engagees">
              <Button bg="white">
                <Text color="green.800" size="md" fontWeight="700">
                  Lesieur Huiles Engagées
                </Text>
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
      <Flex direction="row" columnGap={2} justifyContent="center">
        <Text color="white">© 2024</Text>
        <Text color="white">|</Text>
        <Link href="https://www.miimosa.com" isExternal>
          <Text color="white">MiiMOSA</Text>
        </Link>
        <Text color="white">|</Text>
        <Link
          href="https://mii-bkt-marketing-prod.s3.eu-central-1.amazonaws.com/site-content/CGU_MiiMOSA.pdf#page=25"
          isExternal
        >
          <Text color="white">Mentions légales & CGU</Text>
        </Link>
        <Text color="white">|</Text>
        <Link href="https://miimosa.com/fr/cookies" isExternal>
          <Text color="white">Cookies</Text>
        </Link>
      </Flex>
    </Flex>
  )
}

export default Footer
